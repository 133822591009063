import GraphTabs from 'graph-tabs';

const faqTabs = () => {
  const faqTabs = document?.querySelector('.faq-tabs');
  const tabListItems = document?.querySelectorAll('.tab-list__item');

  if (faqTabs) {
    tabListItems.forEach(item => {
      item.addEventListener('click', () => {
        item.classList.toggle('is-show');

        const itemText = item.querySelector('.tab-list__item-text');

        if (itemText) {
          itemText.classList.toggle('animate__animated');
          itemText.classList.toggle('animate__fadeIn');
        }
      });
    });
    const tabs = new GraphTabs('faqTabs');
  }
};

export default faqTabs;
