// import '../vendor/choices.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import Choices from 'choices.js';

const quizSelect = () => {

  try {

    /** Choices */ 
    const choices = new Choices('.js-choice', {
      renderChoiceLimit: -1,
      itemSelectText: '',
      noResultsText: ''
    });

    // File Upload
    $('#fileInput').on('change', function() {
      readURL(this);
    });
  
    $('#uploadButton').on('click', function() {
      $('#fileInput').trigger('click');
    });
  
    $('#removeButton').on('click', function() {
      removeUpload();
    });
  
    function readURL(input) {
      if (input.files && input.files[0]) {
        let fileName = input.files[0].name;
        let fileSize = input.files[0].size;
        let fileExtension = fileName.split('.').pop().toLowerCase();
        let allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Разрешенные расширения файлов
  
        let maxSize = 5 * 1024 * 1024; // Размер в байтах для 5MB
  
        if (fileSize > maxSize) {
          $('.file-upload__error').text('Файл слишком большой. Максимальный размер файла - 5MB.').show();
          $('#fileInput').val(''); // Очищаем input[type=file]
          return;
        }
  
        if (!allowedExtensions.includes(fileExtension)) {
          $('.file-upload__error').text('Недопустимый формат файла (jpg, jpeg, png или gif).').show();
          $('#fileInput').val(''); // Очищаем input[type=file]
          return;
        }
  
        let reader = new FileReader();
        reader.onloadstart = function() {
          $('.progress').show(); // Показываем прогресс-бар при начале загрузки
        };
        reader.onprogress = function(event) {
          if (event.lengthComputable) {
            let percentComplete = (event.loaded / event.total) * 100;
            $('.progress-bar').css('width', percentComplete + '%');
          }
        };
        reader.onload = function(e) {
          $('.image-upload-wrap').hide();
          $('.file-upload-image').attr('src', e.target.result);
          $('.file-upload-content').css('display', 'flex');
          $('.image-title').html(fileName);
          $('.file-upload__error').hide(); // Скрываем блок с сообщением об ошибке при успешной загрузке
          $('.progress').hide(); // Скрываем прогресс-бар после загрузки
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        removeUpload();
      }
    }
  
    function removeUpload() {
      $('#fileInput').val(''); // Очистка input[type=file]
      $('.file-upload-content').hide();
      $('.image-upload-wrap').show();
      $('.file-upload__error').hide();
      $('.al-progress-bar').css('width', '0%'); // Сброс прогресс-бара при удалении
      $('.al-progress').hide(); // Скрываем прогресс-бар при удалении
    }
  
    $('.image-upload-wrap').on('dragover', function() {
      $(this).addClass('image-dropping');
    });
  
    $('.image-upload-wrap').on('dragleave', function() {
      $(this).removeClass('image-dropping');
    });


  } catch (error) {
    // console.error(error);
  }


};

export default quizSelect;