import AOS from 'aos';
import { CountUp } from 'countup.js';

const animationsApps = () => {
  // Animation
  setTimeout(() => {
    AOS.init({
      disable: 'phone',
      duration: 800,
      delay: 0,
      once: true,
    });

  }, 100);

  function numberAnimations() {
    const options = {
      separator: '',
      duration: 2.5,
    };
  
    const isNumberArr = document.querySelectorAll('.isNumber');
    isNumberArr.forEach(item => {
      const dataNum = parseInt(item.getAttribute('data-num'));
      let myCountUp = new CountUp(item, dataNum, options);
      
      if (!myCountUp.error) {
        setTimeout(() => {
          myCountUp.start();
        }, 200);
      } else {
        console.error(myCountUp.error);
      }
    });
  }
  
  let animationLogged = false;
  
  function checkIfInView() {
    const targetElement = document.querySelector('#aboutUsInNumber');
    
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
  
      if (rect.top <= windowHeight && !animationLogged) {
        numberAnimations();
        animationLogged = true;
      }
    }
  }
  
  // Проверяем положение секции при загрузке страницы
  checkIfInView();
  
  // Отслеживаем событие скролла страницы
  window.addEventListener('scroll', checkIfInView);
  

  
  // function numberAnimations() {
  //   const options = {
  //     separator: '',
  //   };

  //   const isNumberArr = document.querySelectorAll('.isNumber');
  //   isNumberArr.forEach(item => {
  //     const dataNum = parseInt(item.getAttribute('data-num'));
  //     let myCountUp = new CountUp(item, dataNum, options);
      
  //     if (!myCountUp.error) {
  //       setTimeout(() => {
  //         myCountUp.start();
  //       }, 200);
  //     } else {
  //       console.error(myCountUp.error);
  //     }
  //   });
  // }


  // let animationLogged = false;

  // const targetElement = document.querySelector('.aboutUsInNumber__title');
  
  // const observer = new MutationObserver(mutationsList => {
  //   for (let mutation of mutationsList) {
  //     if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
  //       const classList = targetElement.classList;
  //       if (classList.contains('aos-animate') && !animationLogged) {
  //         numberAnimations();
  //         animationLogged = true;
  //       }
  //     }
  //   }
  // });
  
  // observer.observe(targetElement, { attributes: true });
  
  

};

export default animationsApps;