// import '../vendor/choices.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

const quizCheckbox = () => {

  try {
    /** Checkboxes */ 
    const checkboxes = document.querySelectorAll('.option-checkbox');

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('click', () => {
        const label = checkbox.querySelector('label');
        label.click();
      });
    
      const labels = checkbox.querySelectorAll('label');
      labels.forEach(label => {
        label.addEventListener('click', (event) => {
          event.stopPropagation();
        });
      });
    });
    
    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', function() {
            if (this.querySelector('input[type="checkbox"]').checked) {
                const hiddenCheckbox = this.querySelector('.is-hidden');
                hiddenCheckbox.checked = true;
            } else {
                const hiddenCheckbox = this.querySelector('.is-hidden');
                hiddenCheckbox.checked = false;
            }
        });
    });
    

  } catch (error) {
    // console.error(error);
  }


};

export default quizCheckbox;