import Swiper from 'swiper/bundle';
import {throttle} from '../functions/throttle';


const swiperFaq = () => {

  const slider = document?.querySelector('.swiperFaq');
  let mySwiperFaq;

  function mobileSlider() {
    if (window.innerWidth <= 766.98 && slider.dataset.mobile == 'false') {
      mySwiperFaq = new Swiper(slider, {
        slidesPerView: 1.15,
        speed: 700,
        spaceBetween: 20,
        // pagination: {
        //   el: ".reviews__slider .swiper-pagination",
        //   type: "progressbar",
        // },
        navigation: {
          nextEl: '.swiperFaq .swiper-button-next',
          prevEl: '.swiperFaq .swiper-button-prev',
        },
        breakpoints: {
          767: {},
          768: {
            spaceBetween: 25,
            grabCursor: true,
            slidesPerView: 1.35,
            navigation: {
              nextEl: '.reviews__nav .swiper-button-next',
              prevEl: '.reviews__nav .swiper-button-prev',
            },
            pagination: {
              el: '.reviews__nav .swiper-pagination',
              type: 'fraction',
            },
          },
        },
      });

      slider.dataset.mobile = 'true';
    }

    if (window.innerWidth > 768) {
      slider.dataset.mobile = 'false';
      if (slider.classList.contains('swiper-initialized')) {
        mySwiperFaq.destroy();
      }
    }
  }

  if (slider) {
    mobileSlider();

    let func = throttle(mobileSlider);
    window.addEventListener('resize', func);
  }

}

export default swiperFaq;