import superMenu from './components/supermenu';
import mobileMenu from './components/mobmenu';
import swiperReviews from './components/swiperReviews';
import quizSelect from './components/quizSelect';
import quizCheckbox from './components/quizCheckbox';
import quizNumCounter from './components/quizNumCounter';
import quizUploadImg from './components/quizUploadImg';
import quizLogic from './components/quizLogic';
import showMoreExtraProof from './components/showMoreExtraProof';
import swiperServices from './components/swiperServices';
import swiperPhotoGallery from './components/swiperPhotoGallery';
import swiperTeam from './components/swiperTeam';
import swiperNews from './components/swiperNews';
import swiperJobs from './components/swiperJobs';
import swiperGallery from './components/swiperGallery';
import swiperNewsSimple from './components/swiperNewsSimple';
import choicesSelect from './components/choicesSelect';
// import updateServiceItemImages from './components/updateServiceItemImages';
import lightHeader from './components/lightHeader';
import articleSidebar from './components/articleSidebar';
import telLink from './components/telLink';
import scrolling from './components/scrolling';
import fancyApps from './components/fancyapps';
import faqTabs from './components/faqTabs';
import scrollingToNextSection from './components/scrollingToNextSection';
import swiperFaq from './components/swiperFaq';
import trimText from './components/trimText';
import animationsApps from './components/animations';
import showMoreBtn from './components/showMore';
import showMoreJobs from './components/showMoreJobs';
import getHeaderHeight from './functions/header-height';
// import articleTitleHeight from './functions/articleTitle-height';
import './functions/fix-fullheight';
// import activeSocialInMobile from './components/activeSocialInMobile';
import sweetAlert from './components/sweetAlert';
import getRemoveStartQuiz from './components/startQuiz';
import getStepWidth from './components/stepWidth';
import getRemoveSpaces from './components/removeSpaces';
import getIsCheckbox from './components/isCheckbox';
import getSelectArrow from './components/selectArrow';
import getNextBtn from './components/nextBtn';
// import './components/quizValidation';


window.addEventListener('DOMContentLoaded', () => {
  'use strict';
  
  lightHeader();
  superMenu();

  try {
    getRemoveStartQuiz();
    getStepWidth();
    getRemoveSpaces();
    getIsCheckbox();
    getSelectArrow();
    getNextBtn();
  } catch (error) {
    // console.log(error);
  }

  quizSelect();
  quizCheckbox();
  quizNumCounter();
  quizUploadImg();
  quizLogic();
  showMoreExtraProof();

  faqTabs();
  swiperFaq();
  swiperReviews();
  swiperServices();
  swiperPhotoGallery();
  swiperTeam();
  swiperNews();
  swiperJobs();
  swiperNewsSimple();
  swiperGallery();
  choicesSelect();
  // updateServiceItemImages();
  fancyApps();
  mobileMenu();
  setTimeout(() => {
    showMoreJobs();
    showMoreBtn();
  }, 3000);
  articleSidebar();

  getHeaderHeight();
  telLink('.telLink');
  scrollingToNextSection();
  // articleTitleHeight();
  trimText();
  // activeSocialInMobile();

  animationsApps();

  setTimeout(() => {
    scrolling();
  }, 300);

  sweetAlert();

  if (window.matchMedia("(max-width: 767.98px)").matches) {
    $('.footer .social .social__link').addClass('hover');
  }

  document.querySelector('#year').innerHTML = new Date().getFullYear();

});