const getRemoveStartQuiz = () => {

  // setTimeout(() => {
  //   document.body.classList.add('preloader');
  // }, 500);

  // if (localStorage.getItem('userChoice') === 'userChoiceDone') {
  //   document.querySelector('.modal-wrap').style.display = 'none';
  // }

  // const listContainer = document.querySelector('.modal__list');

  // listContainer.addEventListener('click', function (event) {
  //   if (event.target.classList.contains('modal__list-item')) {
  //     document.querySelector('.modal-wrap').style.display = 'none';

  //     localStorage.setItem('userChoice', 'userChoiceDone');

  //     // Если у элемента есть класс "isOff", выводим alert
  //     if (event.target.classList.contains('isOff')) {
  //       Swal.fire({
  //         title: 'Oops, we can’t help you',
  //         text: 'Unfortunately this vacancy required native Ukrainian/Russian speakers',
  //         confirmButtonText: 'Close page',
  //         timer: 5000,
  //         onClose: () => {
  //           window.location.href = 'https://www.google.com/';
  //         }
  //       })
  //       setTimeout(() => {
  //         window.location.href = 'https://www.google.com/';
  //       }, 3000);
  //     }
  //   }
  // });

  const startBtn = document.querySelector('.ejQuiz .start_button');
  setTimeout(() => {
    if (startBtn) {
      startBtn.click();
    }
  }, 200);
}

export default getRemoveStartQuiz;