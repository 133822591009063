const scrollingToNextSection = () => {
  const nextSectionLinks = document.querySelectorAll('.scrollingSection .scrollingToNextSection');

  nextSectionLinks.forEach(nextSectionLink => {
    const hero2Section = nextSectionLink.closest('.scrollingSection');
    const nextSection = hero2Section.nextElementSibling;
    const body = document.body;

    if (nextSection && !body.classList.contains('homePage')) {
      if (!nextSection.classList.contains('offSecondSection')) {
        nextSection.classList.add('secondSection');
        const nextSectionId = nextSection.getAttribute('id');
        nextSectionLink.href = `#${nextSectionId}`;
      }
    }
  });
};

export default scrollingToNextSection;