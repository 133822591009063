import {throttle} from '../functions/throttle';

const articleSidebar = () => {

  function articleSidebarFunc() {
    const articleBodyContent = document.querySelector('.article__body-content');
    const headingElements = articleBodyContent.querySelectorAll('h2, h3, h4');
    const sidebarContent = document.querySelector('.article__body-sidebar-content');
  
    sidebarContent.innerHTML = '';
    let links = [];
  
    headingElements.forEach((headingElement, index) => {
  
      const headingId = `heading-${index + 1}`;
      headingElement.setAttribute('id', headingId);
  
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', `#${headingId}`);
      linkElement.textContent = headingElement.textContent;
      linkElement.classList.add('article__body-sidebar-item', 'scrolling');
  
      links.push(linkElement);
    });
  
    links.forEach(link => {
      sidebarContent.appendChild(link);
    });
  }
  
  if (document.querySelector("#singleArticle") && window.innerWidth > 1200) {
    articleSidebarFunc();
  
    let func = throttle(articleSidebarFunc);
    window.addEventListener('resize', func);
  }
  

}

export default articleSidebar;