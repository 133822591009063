const getNextBtn = () => {
  setTimeout(() => {
    const nextButtons = document.querySelectorAll('input.ays_next.action-button');

    nextButtons.forEach(nextButton => {
      const isFinishButton = nextButton.classList.contains('ays_finish');

      if (isFinishButton) {
        nextButton.value = 'Подать заявку';
      } else {
        nextButton.value = 'Далее';
      }

      const wrapperDiv = document.createElement('div');
      wrapperDiv.classList.add('nextButtonWrap');
    
      // Создать SVG элемент
      const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svgIcon.setAttribute('width', '16');
      svgIcon.setAttribute('height', '16');
      svgIcon.setAttribute('viewBox', '0 0 16 16');
      svgIcon.setAttribute('fill', 'none');
    
      // Создать path элемент внутри SVG
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('d', 'M0.428933 7.99998L14.5711 7.99998M14.5711 7.99998L7.5 0.92891M14.5711 7.99998L7.5 15.071');
      path.setAttribute('stroke', '#999EA1');
      path.setAttribute('stroke-width', '1.5');
    
      // Добавить path в SVG
      svgIcon.appendChild(path);
    
      // Добавить SVG в wrapperDiv
      wrapperDiv.appendChild(svgIcon);

      wrapperDiv.addEventListener('click', () => {
        // Триггерить клик на nextButton
        nextButton.click();
      });
    
      // Обернуть кнопку в созданный div
      nextButton.parentNode.insertBefore(wrapperDiv, nextButton);
      wrapperDiv.appendChild(nextButton);
    });
    
  }, 200);
}

export default getNextBtn;