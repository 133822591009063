// import Swiper from 'swiper/bundle';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { throttle } from '../functions/throttle';

const swiperTeam = () => {

  // Slider init
  const slider = document?.querySelector('.swipTeam');

  let swipTeam = new SwiperCore(slider, {
    slidesPerView: 1.15,
    speed: 700,
    spaceBetween: 20,
    // pagination: {
    //   el: ".reviews__slider .swiper-pagination",
    //   type: "progressbar",
    // },
    navigation: {
      nextEl: '.team__nav .swiper-button-next',
      prevEl: '.team__nav .swiper-button-prev',
    },

    breakpoints: {
      768: {
        slidesPerView: 2.35,
      },
      991: {
        spaceBetween: 25,
        grabCursor: true,
        slidesPerView: 3.35,
        // pagination: {
        //   el: '.team__nav .swiper-pagination',
        //   type: 'fraction',
        // },
      },
    },
  })

  
  let updateSwipTeamContentHeight = () => {
    const swipTeamContent = document?.querySelector('.swipTeam__item-photo');
    if (swipTeamContent) {
      setTimeout(() => {
        const swipTeamContentHeight = swipTeamContent.offsetHeight;
        document.querySelector(':root').style.setProperty('--swipImg-height', `${swipTeamContentHeight}px`);
      }, 100);
    }
  };
  
  if (slider) {
    updateSwipTeamContentHeight();
    let func = throttle(updateSwipTeamContentHeight);
    window.addEventListener('resize', func);
  }
  
}

export default swiperTeam;