import Swal from 'sweetalert2';
import { Fancybox } from "@fancyapps/ui";

const sweetAlert = () => {

  function doneSendForm() {
    const currentLanguage = window.location.pathname;

    const swalWithCustomClass = Swal.mixin({
      customClass: {
        confirmButton: 'btn-reset btn',
      },
      buttonsStyling: false
    })
    
    let title = 'Дякуємо, ваш запит надіслано';
    let text = 'Наш спеціаліст зв’яжеться з вами найближчим часом';
    
    if (currentLanguage.includes('/ru/')) {
      title = 'Спасибо, ваш запрос отправлен';
      text = 'Наш специалист свяжется с вами в ближайшее время';
    }
    
    swalWithCustomClass.fire({
      title: title,
      text: text,
      icon: 'success',
      confirmButtonText: 'Закрити',
      showClass: {
        popup: 'animate__animated  animate__jackInTheBox'
      },
      hideClass: {
        popup: 'animate__animated animate__faster animate__fadeOut'
      }
    })
  }

  
const wpcf7Forms = document.querySelectorAll('.wpcf7');

wpcf7Forms.forEach(function(form) {
  form.addEventListener('wpcf7mailsent', function(event) {
    Fancybox.close();
    doneSendForm();
  }, false);
});


}

export default sweetAlert;