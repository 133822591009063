import { Fancybox } from "@fancyapps/ui";

const fancyApps = () => {
    // Fancybox
    Fancybox.bind('[data-fancybox]', {
      // Custom options for all galleries
    }); 
};

export default fancyApps;
