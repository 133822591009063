const telLink = (selector) => {

  const telLinks = document.querySelectorAll(selector);

  telLinks.forEach(link => {
    const phoneNumber = link.textContent.replace(/[^\d]/g, ''); // Удалить все символы, кроме цифр
    const formattedPhoneNumber = `+${phoneNumber}`;
    
    link.href = `tel:${formattedPhoneNumber}`;
  });

};

export default telLink;