const trimText = () => {
  // Trim text in solo_new
  const textBlocks = document?.querySelectorAll('.n-text');
  
  if (textBlocks) {
    textBlocks.forEach(block => {
      const text = block.textContent.trim();
      const maxLength = 105;
  
      if (text.length > maxLength) {
        const truncatedText = text.slice(0, maxLength) + '...';
        block.textContent = truncatedText;
      }
    });
  }

  // Trim text in articles
  const articleItemDescr = document?.querySelectorAll('.articleItem__descr');
  
  if (articleItemDescr) {
    articleItemDescr.forEach(block => {
      const text = block.textContent.trim();
      const maxLength = 134;
  
      if (text.length > maxLength) {
        const truncatedText = text.slice(0, maxLength) + '...';
        block.textContent = truncatedText;
      }
    });
  }

};

export default trimText;
