// import '../vendor/choices.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

const quizLogic = () => {

  try {

    // Получаем элементы DOM
    const drivingLicenceRadioYes = document.querySelector('input[name="driving_licence"][value="yes"]');
    const drivingLicenceRadioNo = document.querySelector('input[name="driving_licence"][value="no"]');
    const drivingExperienceInput = document.querySelector('.form-input.driving_experience');
    const drivingCategoryCheckbox = document.querySelector('.form-input.driving_category');

    // Функция, которая скрывает/показывает блоки в зависимости от выбора
    function toggleDrivingFields() {
      if (drivingLicenceRadioNo.checked) {
        drivingExperienceInput.style.display = 'none';
        drivingCategoryCheckbox.style.display = 'none';
      } else {
        drivingExperienceInput.style.display = 'block';
        drivingCategoryCheckbox.style.display = 'block';
      }
    }

    // Вызываем функцию при изменении состояния радиокнопок
    drivingLicenceRadioYes.addEventListener('change', toggleDrivingFields);
    drivingLicenceRadioNo.addEventListener('change', toggleDrivingFields);

    // Инициализируем состояние полей при загрузке страницы
    toggleDrivingFields();

    //************** Parts Logic */
    const quizHeadTitle = document.querySelector('.al-quiz__head .h2');
    const quizHeadDescription = document.querySelector('.al-quiz__head > p');
    const quizHeadCounterTotal = document.querySelector('.al-quiz__counter');
    const quizHeadCounterEach = document.querySelector('.al-quiz__counter > span');
    const quizHeadCounterProgressBar = document.querySelector('.al-quiz__progress-bar');
    const quizHeadCounterProgressText = document.querySelector('.al-quiz__progress-text');
    const quizFooter = document.querySelector('.al-quiz__footer');

    const part1Button = document.querySelector('#part-1');
    const part2Button = document.querySelector('#part-2');
    const part3Button = document.querySelector('#part-3');
    const part4Button = document.querySelector('#part-4');
    const btnManager = document.querySelector('#btnManager');

    const part1Blocks = document.querySelectorAll('[data-part_1]');
    const part2Blocks = document.querySelectorAll('[data-part_2]');
    const part3Blocks = document.querySelectorAll('[data-part_3]');
    const part4Blocks = document.querySelectorAll('[data-part_4]');

    const checkboxesPart2 = document.querySelectorAll('[data-part_2] .checkbox');

    function hidePart1Blocks() {
      part1Blocks.forEach(block => {
        block.style.display = 'none';
        part1Button.style.display = 'none';
        btnManager.style.display = 'none';
      });
    }

    function hidePart2Blocks() {
      part2Blocks.forEach(block => {
        block.style.display = 'none';
        part2Button.style.display = 'none';
      });
    }

    function hidePart3Blocks() {
      part3Blocks.forEach(block => {
        block.style.display = 'none';
        part3Button.style.display = 'none';
      });
    }

    function hidePart4Blocks() {
      part4Blocks.forEach(block => {
        block.style.display = 'none';
        part4Button.style.display = 'none';
      });
    }

    function showPart2Blocks() {
      part2Blocks.forEach(block => {
        block.style.display = 'block';
        part2Button.style.display = 'flex';
        quizHeadCounterEach.textContent = "2";
        quizHeadCounterProgressBar.style.width = "50";
        quizHeadTitle.textContent = "Профессии и опыт работы";
        quizHeadDescription.textContent = "Расскажите подробнее о своём опыте";
        quizHeadCounterProgressText.textContent = `${quizHeadCounterTotal.textContent}: ${quizHeadTitle.textContent}`;
      });
    }

    function showPart3Blocks() {
      part3Blocks.forEach(block => {
        if (block.classList.contains('coincided')) {
          block.style.display = 'block';
          part3Button.style.display = 'flex';
          quizFooter.classList.add('al-container--wide');
          quizHeadCounterEach.textContent = "3";
          quizHeadCounterProgressBar.style.width = "75";
          quizHeadTitle.textContent = "Профессиональные навыки";
          quizHeadDescription.textContent = "Расскажите подробнее о своих навыках и стаже по каждому направлению работ";
          quizHeadCounterProgressText.textContent = `${quizHeadCounterTotal.textContent}: ${quizHeadTitle.textContent}`;
        }
      });
    }

    function showPart4Blocks() {
      part4Blocks.forEach(block => {
        block.style.display = 'block';
        part4Button.style.display = 'flex';
        quizFooter.classList.add('al-container--wide');
        quizHeadCounterEach.textContent = "4";
        quizHeadCounterProgressBar.style.width = "75";
        quizHeadTitle.textContent = "Другие специальности и сертификаты";
        quizHeadDescription.textContent = "Расскажите о своих дополнительных навыках";
        quizHeadCounterProgressText.textContent = `${quizHeadCounterTotal.textContent}: ${quizHeadTitle.textContent}`;
      });
    }

    function scrollToQuizForm() {
      const quizForm = document.querySelector('#quizForm');
      quizForm.scrollIntoView({
        behavior: 'smooth'
      });
    }

    hidePart2Blocks();
    hidePart3Blocks();
    hidePart4Blocks();

    part1Button.addEventListener('click', () => {
      // setTimeout(() => {
      // const errorFields = document.querySelectorAll('[data-part_1] .just-validate-error-field');
      // if (errorFields.length === 0) {
      hidePart1Blocks();
      showPart2Blocks();
      scrollToQuizForm();
      // } else {
      // console.log('Есть ошибки валидации.');
      // }
      // }, 500);
    });


    part2Button.addEventListener('click', () => {
      hidePart2Blocks();
      showPart3Blocks();
      scrollToQuizForm();
    });

    part3Button.addEventListener('click', () => {
      hidePart3Blocks();
      showPart4Blocks();
      scrollToQuizForm();
    });

    checkboxesPart2.forEach(checkbox => {
      checkbox.addEventListener('change', () => {
        const checkboxAttribute = checkbox.getAttribute('data-prof');
        part3Blocks.forEach(block => {
          const blockAttribute = block.getAttribute('data-prof');
          if (checkboxAttribute === blockAttribute) {
            if (checkbox.checked) {
              block.classList.add('coincided');
            } else {
              block.classList.remove('coincided');
            }
          }
        });
      });
    });

  } catch (error) {
    console.error(error);
  }



  ////////////////////////////
  $(document).on('submit', '#quizForm', function (event) {
    event.preventDefault();

    var formData = new FormData($(this)[0]);

    // Получаем файл из элемента input[type="file"]
    // var fileInput = document.querySelector('#fileInput');
    // var files = fileInput.files; // Получаем выбранные файлы
    
    // for (var i = 0; i < files.length; i++) {
    //     formData.append('file[]', files[i]); // Добавляем каждый файл в объект FormData под именем 'files[]'
    // }

    // Преобразуем данные языков в строку и добавляем их к объекту formData
    var languagesData = [];
    $('.isLanguageCheckbox .option-checkbox input[type="checkbox"]:checked').each(function (index) {
      var language = $(this).next('label').text().trim();
      var count = $(this).closest('.two-elements').find('.numeric-input').val().trim();

      // Добавляем в массив только каждое нечетное значение
      if ((index + 1) % 2 !== 0) {
        languagesData.push(language + ' ' + count);
      }
    });

    formData.append('languages', languagesData.join(', '));

    // Преобразуем данные driving_category в строку и добавляем их к объекту formData
    var drivingCategoryData = [];
    $('input[name="driving_category[]"]:checked').each(function () {
      drivingCategoryData.push($(this).val());
    });

    formData.append('driving_category', drivingCategoryData.join(', '));

    $.ajax({
      url: 'mail.php',
      type: 'POST',
      data: formData,
      processData: false, // Не обрабатываем данные
      contentType: false, // Не устанавливаем тип контента
      success: function (response) {
        console.log('Письмо успешно отправлено');
        // console.log('Ответ сервера:', response);
      },
      error: function (xhr, status, error) {
        console.error('Ошибка при отправке письма:', error);
      }
    });

    // Выводим данные формы в консоль
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }
    var formDataObject = {};
    for (var pair of formData.entries()) {
      formDataObject[pair[0]] = pair[1];
    }
    console.log(formDataObject);
  });




};

export default quizLogic;