// import '../vendor/choices.js';
import Choices from 'choices.js';

function transliterateAndLowercase(text) {
  const translitTable = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e',
    'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'j', 'к': 'k', 'л': 'l', 'м': 'm',
    'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'shh', 'ъ': '',
    'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya', 'і': 'i', 'І': 'I',
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'E',
    'Ж': 'Zh', 'З': 'Z', 'И': 'I', 'Й': 'J', 'К': 'K', 'Л': 'L', 'М': 'M',
    'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
    'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shh', 'Ъ': '',
    'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
  };

  return text
    .toLowerCase()
    .split('')
    .map(char => translitTable[char] || char)
    .join('');
}

let choicesCustomCategories;

// Создайте асинхронную функцию для загрузки данных из JSON файла
async function loadFlagImagePaths() {
  try {
    const response = await fetch('/wp-content/themes/workset/assets/flags.json');
    if (!response.ok) {
      throw new Error('Failed to load JSON file');
    }
    const flagImagePaths = await response.json();
    return flagImagePaths;
  } catch (error) {
    console.error('Error loading JSON file:', error);
    return {}; // Возвращаем пустой объект в случае ошибки
  }
}

const choicesSelect = async () => {
  const flagImagePaths = await loadFlagImagePaths();
  const selectElement = document?.querySelector('#servicesSelect');

  if (selectElement) {
    choicesCustomCategories = new Choices(selectElement, {
      renderChoiceLimit: -1,
      placeholder: false,
      itemSelectText: '',
      noResultsText: '',
      callbackOnCreateTemplates: function (template) {
        const classNames = this.config.classNames;
        const itemSelectText = this.config.itemSelectText;

        return {
          choice: function (classN, data) {
            const itemClass = data.disabled
              ? `${classNames.item} ${classNames.itemDisabled}`
              : `${classNames.item} ${classNames.itemSelectable}`;

            const roleAttr = data.groupId > 0 ? 'role="treeitem"' : 'role="option"';
            const disabledAttr = data.disabled
              ? 'data-choice-disabled aria-disabled="true"'
              : 'data-choice-selectable';

            const imagePath = flagImagePaths[data.label] || '';

            return template(`
              <div class="${itemClass}"
                data-select-text="${itemSelectText}"
                data-choice ${disabledAttr}
                data-id="${data.id}"
                data-value="${data.value}"
                data-label="${data.label}"
                ${roleAttr}>
                <img src="${imagePath}" class="selected-option-image" alt="${data.value}">
                ${data.label}
              </div>
            `);
          },
        };
      },
    });
  }

  const isServicesLinks = document.querySelectorAll('.isServices > li > .nav__link');
  let selectedCategoryStorageLocal;
  selectedCategoryStorageLocal = localStorage.getItem('selectedCategory');
  if (selectElement) {
    choicesCustomCategories.setChoiceByValue(selectedCategoryStorageLocal);
  }

  isServicesLinks.forEach((link) => {
    link.addEventListener('click', () => {
      window.location.reload();
      const linkText = link.textContent;
      const transformedText = transliterateAndLowercase(linkText);
      localStorage.setItem('selectedCategory', transformedText);
      if (selectElement) {
        choicesCustomCategories.setChoiceByValue(selectedCategoryStorageLocal);
      }
    });
  });

};

export default choicesSelect;

