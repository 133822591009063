const getIsCheckbox = () => {
  setTimeout(() => {
    // Находим все блоки .ays-quiz-answers
    const quizAnswersBlocks = document.querySelectorAll('.ays-quiz-answers');
  
    // Перебираем каждый блок .ays-quiz-answers
    quizAnswersBlocks.forEach((quizAnswersBlock) => {
      // Находим все элементы input внутри текущего блока
      const inputs = quizAnswersBlock.querySelectorAll('.ays-field input');
  
      // Проверяем каждый input на тип "checkbox" или "radio"
      const hasCheckbox = Array.from(inputs).some((input) => {
        return input.type === 'checkbox';
      });
      
      const hasRadio = Array.from(inputs).some((input) => {
        return input.type === 'radio';
      });
  
      // Если найден хотя бы один checkbox, добавляем класс "isCheckbox"
      if (hasCheckbox) {
        quizAnswersBlock.classList.add('isCheckbox');
      }

      // Если найден хотя бы один radio, добавляем класс "isRadio"
      if (hasRadio) {
        quizAnswersBlock.classList.add('isRadio');
      }
    });
  }, 200);
}

export default getIsCheckbox;
