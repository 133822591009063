const getRemoveSpaces = () => {
  setTimeout(() => {
    const questionCounters = document?.querySelectorAll('.ejQuiz .step .ays-question-counter');

    if (questionCounters) {
      questionCounters.forEach((counter) => {
        if (counter && counter.textContent) {
          const trimmedText = counter.textContent.replace(/\s/g, '');
          
          counter.textContent = trimmedText;
        }
      });
    }
  }, 200);
}

export default getRemoveSpaces;
