import {throttle} from '../functions/throttle';

const getStepWidth = () => {
  function getStepWidthFunc() {
      const stepElement = document?.querySelector('.step.active-step > .ays-abs-fs');
  
      if (stepElement) {
        const stepWidth = stepElement.offsetWidth;
        document.querySelector(':root').style.setProperty('--step-width', `${stepWidth}px`);
      }
  }

  setTimeout(() => {
    getStepWidthFunc();
  }, 700);

  let func = throttle(getStepWidthFunc, 300);
  window.addEventListener('resize', func);
}

export default getStepWidth;