// import Swiper from 'swiper/bundle';
import AOS from 'aos';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { throttle } from '../functions/throttle';

const swiperJobs = () => {

  // Slider init
  const slider = document?.querySelector('.swipJobs');

  let swipNews = new SwiperCore(slider, {
    slidesPerView: 1.15,
    speed: 700,
    spaceBetween: 20,
    navigation: {
      nextEl: '.newsJobs .swiper-button-next',
      prevEl: '.newsJobs .swiper-button-prev',
    },

    breakpoints: {
      768: {
        slidesPerView: 2.35,
      },
      992: {
        slidesPerView: 2.35,
      },
      1200: {
        spaceBetween: 25,
        grabCursor: true,
        slidesPerView: 3,
      },
    },
  })

  
}

export default swiperJobs;