// import '../vendor/choices.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

const quizNumCounter = () => {

  try {

    /** Language Number counter */ 
    const checkboxes = document.querySelectorAll('[data-toggle="counter"] input[type="checkbox"]');
    checkboxes.forEach(function (checkbox) {
      checkbox.addEventListener("change", function () {
        const counter = this.parentElement.nextElementSibling;
        if (this.checked) {
          counter.style.display = "flex";
        } else {
          counter.style.display = "none";
        }
      });
    });

    /** Number counter events */ 
    // Обработчик события для поля ввода
    $(document).on('input', '.numeric-input', function () {
      const $input = $(this);
      let value = $input.val().replace(/\D/g, ''); // Оставляем только цифры
      value = Math.max(1, Math.min(10, value)); // Гарантируем, что значение находится в диапазоне от 1 до 10
      $input.val(value); // Устанавливаем очищенное значение
    });

    // Обработчик события для кнопки "минус"
    $(document).on('click', '.minus', function () {
      const $input = $(this).parent().find('.numeric-input');
      let count = parseInt($input.val()) - 1;
      count = Math.max(1, count); // Гарантируем, что значение не опустится ниже 1
      $input.val(count).change(); // Устанавливаем новое значение и запускаем событие изменения
      return false; // Предотвращаем переход по ссылке (если это ссылка)
    });

    // Обработчик события для кнопки "плюс"
    $(document).on('click', '.plus', function () {
      const $input = $(this).parent().find('.numeric-input');
      let count = parseInt($input.val()) + 1;
      count = Math.min(10, count); // Гарантируем, что значение не превысит 10
      $input.val(count).change(); // Устанавливаем новое значение и запускаем событие изменения
      return false; // Предотвращаем переход по ссылке (если это ссылка)
    });

  } catch (error) {
    // console.error(error);
  }


};

export default quizNumCounter;