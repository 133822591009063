import SwiperCore, { Navigation } from 'swiper/core';
import { throttle } from '../functions/throttle';

const swiperGallery = () => {
  const slider1 = document?.querySelector('.swipGallery-1');
  const slider2 = document?.querySelector('.swipGallery-2');
  
  function initSlider(slider, buttonsSelector) {
    let mySwiper = new SwiperCore(slider, {
      slidesPerView: 1.15,
      speed: 700,
      spaceBetween: 20,
      navigation: {
        prevEl: buttonsSelector + ' .swiper-button-prev',
        nextEl: buttonsSelector + ' .swiper-button-next',
      },
      breakpoints: {
        768: {},
        992: {},
        1200: {
          spaceBetween: 25,
          slidesPerView: 2.35,
          navigation: {
            prevEl: buttonsSelector + ' .swiper-button-prev.forPc',
            nextEl: buttonsSelector + ' .swiper-button-next.forPc',
          },
        },
      },
    });
  }

  function mobileSlider1() {
    if (window.innerWidth < 1200) {
      const sliderButtons = document.querySelector('.ejSwipGallery-1 .slider-buttons-1.hide-sm');

      if (sliderButtons) {
        sliderButtons.innerHTML = '';
      }
    }

    initSlider(slider1, '.ejSwipGallery-1');
  }

  function mobileSlider2() {
    if (window.innerWidth < 1200) {
      const sliderButtons = document.querySelector('.ejSwipGallery-2 .slider-buttons-2.hide-sm');

      if (sliderButtons) {
        sliderButtons.innerHTML = '';
      }
    }

    initSlider(slider2, '.ejSwipGallery-2');
  }

  if (slider1) {
    mobileSlider1();

    let func1 = throttle(mobileSlider1);
    window.addEventListener('resize', func1);
  }

  if (slider2) {
    mobileSlider2();

    let func2 = throttle(mobileSlider2);
    window.addEventListener('resize', func2);
  }
}

export default swiperGallery;
