// import Swiper from 'swiper/bundle';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import { throttle } from '../functions/throttle';

const swiperReviews = () => {

  // Slider init
  const slider = document?.querySelector('.swipReviews');

  let swipReviews = new SwiperCore(slider, {
    slidesPerView: 1.15,
    speed: 700,
    spaceBetween: 20,
    pagination: {
      el: ".reviews__slider .swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: '.reviews__slider .swiper-button-next',
      prevEl: '.reviews__slider .swiper-button-prev',
    },

    breakpoints: {
      768: {
        spaceBetween: 25,
        grabCursor: true,
        slidesPerView: 1.35,
        navigation: {
          nextEl: '.reviews__nav .swiper-button-next',
          prevEl: '.reviews__nav .swiper-button-prev',
        },
        pagination: {
          el: '.reviews__nav .swiper-pagination',
          type: 'fraction',
        },
      },
    },
  })

  
  // Ровняем картинку к тексту 
  let updateSwipReviewsContentHeight = () => {
    const swipReviewsContent = document?.querySelector('.swipReviews__item-text');
    if (swipReviewsContent) {
      const swipReviewsContentHeight = swipReviewsContent.offsetHeight;
      document.querySelector(':root').style.setProperty('--swipText-height', `${swipReviewsContentHeight}px`);
      // console.log('throttle')
    }
  };
  
  if (slider) {
    updateSwipReviewsContentHeight();
    // Ровняем при ресайзе тоже
    let func = throttle(updateSwipReviewsContentHeight);
    window.addEventListener('resize', func);
  }
  
}

export default swiperReviews;