const showMoreBtn = () => {
  try {
    const showMore = document.querySelector('#showMore');
    const products = document.querySelectorAll('.articleItem');
    let itemsToShow = 6;

    if (products.length <= itemsToShow) {
      showMore.style.display = 'none';
    }

    showMore.addEventListener('click', () => {
      itemsToShow += 3;

      products.forEach((el, index) => {
        if (index >= itemsToShow - 3 && index < itemsToShow) {
          el.classList.add('is-visible', 'animate__animated', 'animate__fadeInUp');
        }
      });

      if (itemsToShow >= products.length) {
        showMore.style.display = 'none';
      }
    });
  } catch (error) {}
};

export default showMoreBtn;
