// import Swiper from 'swiper/bundle';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import {throttle} from '../functions/throttle';


const swiperNewsSimple = () => {

  const slider = document?.querySelector('.swiperNewsSimple');
  let mySwiper;

  function mobileSlider() {
    // if (window.innerWidth <= 766.98 && slider.dataset.mobile == 'false') {
      mySwiper = new SwiperCore(slider, {
        slidesPerView: 1.15,
        speed: 700,
        spaceBetween: 20,
        // pagination: {
        //   el: ".reviews__slider .swiper-pagination",
        //   type: "progressbar",
        // },
        navigation: {
          nextEl: '.swiperNewsSimple .swiper-button-next',
          prevEl: '.swiperNewsSimple .swiper-button-prev',
        },
        breakpoints: {
          767: {},
          768: {
            spaceBetween: 25,
            grabCursor: true,
            slidesPerView: 3,
            navigation: {
              nextEl: '.reviews__nav .swiper-button-next',
              prevEl: '.reviews__nav .swiper-button-prev',
            },
            pagination: {
              el: '.reviews__nav .swiper-pagination',
              type: 'fraction',
            },
          },
        },
      });

      slider.dataset.mobile = 'true';
    // }

    // if (window.innerWidth > 768) {
    //   slider.dataset.mobile = 'false';
    //   if (slider.classList.contains('swiper-initialized')) {
    //     mySwiper.destroy();
    //   }
    // }
  }

  if (slider) {

    mobileSlider();

    let func = throttle(mobileSlider);
    window.addEventListener('resize', func);
  }

}

export default swiperNewsSimple;