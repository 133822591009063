const getSelectArrow = () => {
  setTimeout(() => {
    const select2Arrow = document.querySelector('.select2-selection__arrow');

    if (select2Arrow) {
      select2Arrow.innerHTML = '<img src="/wp-content/themes/worksetquiz/assets/img/angle-down.svg" class="isArrow" alt="⇩" width="24" height="24">';
    }
  }, 700);
}

export default getSelectArrow;