import { throttle } from './throttle';
const fixFullheight = () => {
  let vh = window.innerHeight;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

let fixHeight = throttle(fixFullheight);

fixHeight();

if (window.matchMedia('(min-width: 1200px)').matches) {
  window.addEventListener('resize', fixHeight);
}
